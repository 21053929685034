import React, { useState } from 'react';
import { View } from 'react-native';
import { Button } from '@rneui/themed';
import CreateBet from './CreateBet';
import FilterOptions from './FilterOptions';
import { listHeaderButtonStyles } from '../styles';
import { PRIMARY_BLACK_COLOR } from '../../../global/styles';

export default (props) => {
    const [isCreateVisible, setIsCreateVisible] = useState(false);
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    return (
        <>
            <View style={{ flexDirection: 'row' }}>
                <Button
                    // size={'sm'}
                    title="Create Bet"
                    onPress={props.onDelete}
                    containerStyle={{ ...listHeaderButtonStyles.containerStyle, paddingRight: 5 }}
                    buttonStyle={listHeaderButtonStyles.buttonStyle}
                    titleStyle={{ fontSize: 13 }}
                    disabled={props.deleteDisabled}
                    onPress={() => { setIsCreateVisible(!isCreateVisible) }}
                />
                <Button
                    // size={'sm'}
                    title="Filter Options"
                    onPress={props.onShare}
                    containerStyle={{ ...listHeaderButtonStyles.containerStyle, paddingLeft: 5 }}
                    buttonStyle={{ ...listHeaderButtonStyles.buttonStyle, borderColor: PRIMARY_BLACK_COLOR, backgroundColor: PRIMARY_BLACK_COLOR }}
                    titleStyle={{ fontSize: 13 }}
                    onPress={() => { setIsFilterVisible(!isFilterVisible) }}
                />
            </View>
            <CreateBet
                isVisible={isCreateVisible}
                onBetCreated={props.onBetCreated}
                onBackdropPress={() => { setIsCreateVisible(!isCreateVisible) }}
                onExit={() => { setIsCreateVisible(!isCreateVisible) }}
            />
            <FilterOptions
                isVisible={isFilterVisible}
                onFilterApplied={props.onFilterApplied}
                onBackdropPress={() => { setIsFilterVisible(!isFilterVisible) }}
                onExit={() => { setIsFilterVisible(!isFilterVisible) }}
            />
        </>
    )
}