import axios from 'axios';
import { AuthStorage } from '../storage/auth-storage';
import { AppStorage } from '../storage/app-storage';
import config from '../../config/config.json';
const API_ENDPOINT = config.API_ENDPOINT;

const buildAuthHeader = async () => {
    return {
        headers: {
            Authorization: await AuthStorage.getAuthToken()
        }
    }
}

const createBet = async (bet) => {
    let response = await axios.post(`${API_ENDPOINT}/createSession`, {
        ...bet
    }, {
        ...(await buildAuthHeader())
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const joinBet = async (bet) => {
    let response = await axios.post(`${API_ENDPOINT}/joinSession`, {
        ...bet
    }, {
        ...(await buildAuthHeader())
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

/**
 * @param {*} bet 
 * @docs TODO: this should be stellar local funding to bet to consolidate security 
 * // NOTES IN BACK END ALL ABOUT THIS PROCESS (rip this out to local stellar utilization) 
 */
const fundBet = async (bet) => {
    let response = await axios.post(`${API_ENDPOINT}/fundSession`, {
        ...bet
    }, {
        ...(await buildAuthHeader())
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const getBets = async () => {
    const filters = await AppStorage.getFilters();
    let params = {};
    if (filters != null) {
        params.filters = JSON.stringify(filters);
    }
    let response = await axios.get(`${API_ENDPOINT}/getSessions`, {
        ...(await buildAuthHeader()), params
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const getUserBets = async (filter = undefined) => {
    let response = await axios.get(`${API_ENDPOINT}/getUserSessions`, {
        ...(await buildAuthHeader()), params: {
            filter
        }
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const getWalletBalance = async (type = 'stellar', pk) => {
    let response = await axios.get(`${API_ENDPOINT}/getWalletBalance`, {
        ...(await buildAuthHeader()), params: { type, pk }
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const deleteBet = async (id) => {
    let response = await axios.delete(`${API_ENDPOINT}/deleteSession/${id}`, { ...(await buildAuthHeader()) });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const getUserProfile = async () => {
    let response = await axios.get(`${API_ENDPOINT}/getProfile`, { ...(await buildAuthHeader()) });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const updateUserGameProfiles = async (gameProfiles) => {
    let response = await axios.post(`${API_ENDPOINT}/updateProfile`, {
        action: 'game-profiles-update',
        gameProfiles
    }, {
        ...(await buildAuthHeader())
    });
    if (response.status !== 200) {
        return null;
    }
    return response.data;
}

const checkGamerTagValid = async (game, gamerTag) => {
    try {
        let response = await axios.get(`${API_ENDPOINT}/checkGamerTagValid`, {
            ...(await buildAuthHeader()), params: {
                game,
                gamerTag
            }
        });
        if (response.status !== 200) {
            return false
        }
        return true;
    } catch (e) {
        return false;
    }
}

export default {
    createBet,
    joinBet,
    fundBet,
    getBets,
    getUserBets,
    getWalletBalance,
    deleteBet,
    getUserProfile,
    updateUserGameProfiles,
    checkGamerTagValid
}