import React from 'react';
import { Card, Text } from '@rneui/themed';
import BetCardDetails from './BetCardDetails';
import BetCardButton from './BetCardButton';
import { cardStyles } from '../../styles';

export default (props) => {
    return (
        <Card containerStyle={cardStyles.activeHistoryBetCardContainerSyle}>
            <Text style={{ margin: 0, textAlign: 'left' }}>{props.title}</Text>
            <Card.Divider style={{ marginTop: 4, marginBottom: 4 }} />
            <BetCardDetails
                fontSize={15}
                game={props.game}
                type={props.type}
                amount={props.amount}
                privacy={props.privacy}
                created={props.created}
            />
            {/* TODO: add icons to right like for info or something */}
            <BetCardButton title="Join" onPress={props.onJoin} />
        </Card>
    );
}