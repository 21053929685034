import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon } from '@rneui/themed';
import { Header as CustomRNEHeader } from '../Header';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { sharedRoundBoarderStyle } from '../../styles'

export default (props) => {
    return (
        <>
            <CustomRNEHeader
                edges={['left', 'right']}
                backgroundColor='white'
                leftComponent={<TouchableOpacity onPress={() => { props.onExit() }}>
                    <Icon type="font-awesome" name="close" color="teal" />
                </TouchableOpacity>}
                style={{
                    padding: 0, margin: 0
                }}
                containerStyle={{
                    ...sharedRoundBoarderStyle
                }}
                centerContainerStyle={{
                    width: '100%'
                }}
                centerComponent={{ text: props.headerText, style: styles.heading }}
            />
            {!props.bottomSpace && <View style={{ borderBottomColor: 'teal', borderBottomWidth: 1, marginBottom: 10 }} />}
        </>
    );
};

const styles = StyleSheet.create({
    heading: {
        color: 'teal',
        fontSize: 22,
        // fontWeight: 'bold',
    }
});