import React, { useEffect, useRef, useState } from 'react';
import {
    ActivityIndicator, KeyboardAvoidingView, Keyboard, Platform, ScrollView, StyleSheet,
    TouchableOpacity, TouchableWithoutFeedback, useWindowDimensions, View
} from 'react-native';
import { Input } from '@rneui/themed';
import Message from './Message';
import Icon from 'react-native-vector-icons/FontAwesome5';
import * as websocketClient from '../../../../services/websocket/client';

export default (props) => {
    const viewport = useWindowDimensions();

    const [isLoading, setIsLoading] = useState(false);
    const [connected, setConnected] = useState(false);
    const [message, setMessage] = useState('')
    const [messages, setMessages] = useState([]);
    const scrollViewRef = useRef();

    const openWss = async () => {
        if (connected) {
            return;
        }

        setIsLoading(true)
        websocketClient.openStreamWithSessionId(props.chatSessionData.sessionId, handleResponse, () => {
            setConnected(false);
            setIsLoading(false)
        }, () => {
            setConnected(true);
            getMessageHistory();
            setIsLoading(false)
        }, () => {
            setConnected(false);
            setIsLoading(false)
        }, true);
    }

    const handleResponse = (response) => {
        console.log("RESPONSE::::", response)
        if (response === null || response === undefined || response.data === null
            || response.data === undefined) {
            return;
        }
        let data;
        try {
            data = JSON.parse(response.data);
        } catch (e) {
            return;
        }
        if (data.length === 0) {
            return;
        }

        const mapFunction = (item) => {
            return {
                index: Math.random(),
                owner: item.owner,
                name: item.owner,
                sentAt: new Date(item.sentAt),
                sender: { name: item.owner, avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
                text: item.message
            }
        }

        // TODO: make, this better
        // this is handling an array (history of input incoming response)
        if (data.length > 1 || Array.isArray(data)) {
            // console.log("DATA")
            // console.log(data)
            data.sort((a, b) => new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime());
            const messages = data.map(x => mapFunction(x));
            // console.log(data)
            // @ts-ignore
            setMessages((prevState) => [...prevState, ...messages]);
            return;
        }
        // handle single message items
        const message = [data].map(x => mapFunction(x))[0];
        // @ts-ignore
        setMessages((prevState) => [...prevState, message]);

        // if (this.state.isLoading) {
        //     this.setState({ isLoading: false });
        // }
    };

    const sendMessage = () => {
        websocketClient.sendMessage(props.chatSessionData.sessionId, message, props.chatSessionData.username);
        setMessage('')
    }

    const getMessageHistory = () => {
        websocketClient.sendMessageObject({ sessionId: props.chatSessionData.sessionId, type: "HISTORY" });
    }

    useEffect(() => {
        openWss();
    }, [connected]);

    useEffect(() => {
        return () => {
            console.log('componentWillUnmount', 'close connection');
            websocketClient.closeConnection();
        };
    }, []);

    return (
        <KeyboardAvoidingView
            keyboardVerticalOffset={100}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{ flex: 1 }}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>

                <>
                    <ActivityIndicator color="teal" animating={isLoading} size="large" />
                    <ScrollView
                        ref={scrollViewRef}
                        onContentSizeChange={(width, height) =>
                            scrollViewRef.current.scrollTo({ y: height })
                        }
                        style={{
                            backgroundColor: 'white',
                            // maxHeight: viewport.height.toFixed(0) - 400,
                            height: '100%',
                            // paddingTop: 20,
                            paddingRight: 20,
                            paddingLeft: 20
                        }}
                        showsVerticalScrollIndicator={false}
                    >
                        <View
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginVertical: 5,
                            }}
                        ></View>
                        {messages.map((message, index) => (
                            <Message
                                key={index}
                                // currentUser={auth.currentUser.uid.toLowerCase()}
                                // owner={message.receiverId.toLowerCase()}
                                currentUser={props.chatSessionData.username.toLowerCase()}
                                owner={message.owner.toLowerCase()}
                                message={message}
                            />
                        ))}
                    </ScrollView>

                    {/* INPUT VIEW */}
                    <View style={[styles.flexify, styles.bottomStyle, styles.shadow]}>
                        {/* <TouchableOpacity
                            style={{
                                paddingVertical: 5,
                                paddingHorizontal: 7,
                                borderRadius: 50,
                                backgroundColor: '#122643',
                            }}
                            activeOpacity={0.5}
                        >
                            <Icon name="plus" size={12} color="white" />
                        </TouchableOpacity> */}

                        {/* original view */}
                        <View style={{ flex: 1, justifyContent: 'space-around' }}>
                            <Input
                                placeholder="Write a message..."
                                inputContainerStyle={{ borderBottomWidth: 0 }}
                                onSubmitEditing={() => sendMessage()}
                                onChangeText={(text) => setMessage(text)}
                                value={message}
                                inputStyle={{ fontSize: 14 }}
                            // autoFocus={true}
                            />
                        </View>

                        <TouchableOpacity
                            style={{
                                paddingVertical: 13,
                                paddingHorizontal: 15,
                                borderRadius: 50,
                                backgroundColor: 'black',
                            }}
                            activeOpacity={0.5}
                            disabled={message.length < 1}
                            onPress={() => sendMessage()}
                        >
                            <Icon name="arrow-right" size={22} color="white" />
                        </TouchableOpacity>
                    </View>
                </>

            </TouchableWithoutFeedback>
        </KeyboardAvoidingView>
    )
}

const styles = StyleSheet.create({
    flexify: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffsetWidth: 0,
        shadowOffsetHeight: 2,
        shadowOpacity: 0.2,
        shadowRadius: 3,
        backgroundColor: 'white',
    },
    bottomStyle: {
        paddingHorizontal: 5,
        paddingVertical: 5,
    },
})