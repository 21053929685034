import CLASH_ROYALE_ICON from '../../../assets/game-icons/clash-royale-card-icon.png';
import HALO_INFINITE_ICON from '../../../assets/game-icons/clash-royale-card-icon.png';

const getGameAssetIcon = (type) => {
    if (type?.toUpperCase() === 'CR' || type?.toUpperCase() === 'CLASH_ROYALE' ||  type?.toUpperCase() === 'CLASHROYALE') {
        return CLASH_ROYALE_ICON;
    }
    if (type?.toUpperCase() === 'HALO') {
        return HALO_INFINITE_ICON;
    }
    return null;
}
export {
    getGameAssetIcon
}