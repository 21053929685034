import React from 'react';
import { Modal } from 'react-native';
import { Overlay } from '@rneui/themed';
import { sharedRoundBoarderStyle } from '../../styles';

export default (props) => {
   const  overlayStyleOverrides = props.overlayStyleOverrides || {};
    return (
        <>
            {/* https://github.com/react-native-elements/react-native-elements/blob/3418976f07a687d06f464288c2de7a4d48f5208c/packages/base/src/Overlay/Overlay.tsx */}
            {/* confirmed here the padding is 10, so we are removing that to control our style for only inner container values to have that padding and now the header */}
            <Overlay
                isVisible={props.isVisible}
                overlayStyle={{
                    width: '90%',
                    height: '75%',
                    padding: 0,
                    ...sharedRoundBoarderStyle,
                    ...overlayStyleOverrides
                }}
                // fullScreen={true}
                onPressIn={() => { return; }}
                onBackdropPress={props.onBackdropPress}
                ModalComponent={Modal}
                // slide type animates from botttom
                animationType="fade"
            >
                {props.children}
            </Overlay>
        </>
    );
};