import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Header as HeaderRNE, Icon, Text } from '@rneui/themed';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Toast from 'react-native-toast-message';
import { UserProfile, Wallet } from '../views';
import { PRIMARY_BLACK_COLOR } from '../../../global/styles';

export default (props) => {
    const [isProfileVisible, setIsProfileVisible] = React.useState(false);
    const [isWalletVisbile, setIsWalletVisbile] = React.useState(false);

    const profileNavigate = () => {
        setIsProfileVisible(true)
    };

    const walletNavigate = () => {
        setIsWalletVisbile(true);
    };

    return (
        <>
            <HeaderRNE
                backgroundColor={PRIMARY_BLACK_COLOR}
                leftComponent={<Text h4 style={{ color: 'white', }} onPress={() => { props.onPressLeftComponent() }}>ewager</Text>}
                rightComponent={
                    <View style={styles.headerRight}>
                        <TouchableOpacity onPress={profileNavigate}>
                            <Icon type="font-awesome" name="user" color="white" />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ marginLeft: 20 }} onPress={walletNavigate}>
                            <Icon type='ionicon' name="wallet" color="white" />
                        </TouchableOpacity>
                    </View>
                }
            // centerComponent={{ text: 'Header', style: styles.heading }}
            />
            <UserProfile
                isVisible={isProfileVisible}
                onLogout={() => {
                    props.navigation && props.navigation.replace('LoginNavigator');
                    Toast.show({ type: 'success', text1: "Logout Successful." });
                }}
                onExit={() => { setIsProfileVisible(false) }}
            />
            <Wallet
                isVisible={isWalletVisbile}
                onExit={() => { setIsWalletVisbile(false) }}
            />
        </>
    );
};

const styles = StyleSheet.create({
    heading: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerRight: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    subheaderText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});