import React from 'react';
import { Button } from '@rneui/themed';
import { sharedButtonStyles } from '../../styles';

export default (props) => {
    const style = props.style ? { ...props.style } : {};
    const buttonStyle = props.buttonStyle ? { ...sharedButtonStyles.buttonStyle, ...props.buttonStyle } : sharedButtonStyles.buttonStyle;
    const titleStyle = props.titleStyle ? { ...sharedButtonStyles.titleStyle, ...props.titleStyle } : sharedButtonStyles.titleStyle;
    const containerStyle = props.containerStyle ? { ...sharedButtonStyles.containerStyle, ...props.containerStyle } : sharedButtonStyles.containerStyle;
    return (<Button
        size={props.size || undefined}
        title={props.title}
        style={style}
        buttonStyle={buttonStyle}
        titleStyle={titleStyle}
        containerStyle={containerStyle}
        onPress={props.onPress}
        disabled={props.disabled ? props.disabled : false}
    />)
}