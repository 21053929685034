import React, { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { Input } from '@rneui/themed';
import { BetViewContainer } from './containers';
import { betSubmissionWalletCheck, fetchCurrentGameSelectionTag, fetchCurrentWallet } from './shared';
import { validator } from '../data';
import { Bet } from '../components';
import { LabelText, GameUsernameInput } from '../components';
import BetRowDetail from '../components/Bet/BetRowDetail';
import { config as toastConfig } from '../../../config/toastConfig'
import { appApi } from '../../../services/api';

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [gameUsername, setGameUsername] = useState('');
    const [publicWalletAddress, setPublicWalletAddress] = useState('');
    const bet = props?.bet;

    useEffect(() => {
        fetchCurrentWallet(setPublicWalletAddress);
    }, []);

    useEffect(() => {
        let selection = '';
        if (bet?.game === 'clashroyale') {
            selection = "Clash Royale";
        }
        // TODO: make this better and support halo
        fetchCurrentGameSelectionTag(setGameUsername, selection);
    }, props?.bet);

    const handleJoinPressLoading = async () => {
        setIsLoading(true);
        await handleJoinPress();
        setIsLoading(false);
    }

    const handleJoinPress = async () => {
        const wallet = await betSubmissionWalletCheck(Toast, bet.amount);
        if (!wallet) {
            return;
        }

        // build createBet
        const joinBet = {
            sessionId: bet.id,
            gameId: gameUsername,
            wallet: wallet.pk,
            password: null
        }

        if (!validator.validateBet(joinBet)) {
            Toast.show({ type: 'error', text1: 'Fill out all necessary items!' });
            return;
        }

        setIsLoading(true);
        try {
            // build bet, join it and show success
            const joinBetResult = await appApi.joinBet(joinBet);
            // TODO: amount needs to support more than one player type for funding, also other work on funding here in general this is POC idea
            const fundBetResult = await appApi.fundBet({ id: joinBetResult.id, pk: wallet.pk, sk: wallet.sk, amount: (joinBetResult.amount / 2) });
        } catch (e) {
            Toast.show({ type: 'error', text1: 'Bet Join Failure, Try Again!' });
            return;
        } finally {
            setIsLoading(false);
        }

        // show success for a second
        Toast.show({ type: 'success', text1: 'Bet Joined Successfully!' });

        // 1.5 seconds display success and then close
        await new Promise(res => setTimeout(res, 1500));
        props.onExit();
    }

    return (
        <>
            <BetViewContainer
                headerText='Join Bet'
                isVisible={props.visible}
                onPressIn={() => { return; }}
                onBackdropPress={props.onExit}
                onExit={props.onExit}
                bottomButtonOptions={{
                    iconName: 'handshake-o',
                    title: 'Join Bet',
                    loading: isLoading,
                    onPress: handleJoinPressLoading
                }}
            >

                <Bet.BetCardDetails
                    fontSize={16}
                    game={bet?.game}
                    type={bet?.type}
                    amount={bet?.amount}
                    privacy={bet?.privacy}
                    created={bet?.created}
                />

                <BetRowDetail fontSize={16} leftText='Desired Play Date:' rightText={bet?.openSessionDate} />

                <GameUsernameInput
                    value={gameUsername}
                    onChangeText={setGameUsername}
                />

                <LabelText text='Payout Wallet Public Address (XLM):' />
                <Input
                    placeholder="GBEHP2GSWQHMNMFQ6IUXZF5VBKEFJ5ABLLOIC44W6DS6JINBZVX3KLEX"
                    value={publicWalletAddress}
                    onChangeText={setPublicWalletAddress}
                />

                {/* Has to be at bottom */}
                <Toast config={toastConfig} />
            </BetViewContainer>
        </>
    )
}