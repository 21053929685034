import { AuthStorage } from '../storage/auth-storage';

/**
 * @param {*} sessionId 
 * AUTH CONNECTION BUILDING COMPONENT
 */
export const generateConnectionUrl = async (sessionId) => {
	const authToken = await AuthStorage.getAuthToken();
	// const WEBSOCKET_URL_CONNECTION = `ary1j7jh52.execute-api.us-east-1.amazonaws.com/wss/?Authorization=${authToken}&sessionId=${sessionId}`;
	const WEBSOCKET_URL_CONNECTION = `chat.ewager.online/?Authorization=${authToken}&sessionId=${sessionId}`;
	return WEBSOCKET_URL_CONNECTION;
}

export const openStreamWithSessionId = async (sessionId, callback, onerror, onopen, onclose, isSsl = true) => {
	const URL = await generateConnectionUrl(sessionId);
	await openStream(URL, callback, onerror, onopen, onclose, isSsl);
}

/*** WEBSOCKET CONTROL ***/
let ws = null;
export const openStream = async (address, callback, onerror, onopen, onclose, isSsl = true) => {
	if (ws === null) {
		const socketType = isSsl ? 'wss' : 'ws';
		ws = new WebSocket(`${socketType}://${address}`);
	}
	ws.onerror = () => {
		console.log('WebSocket error');
		onerror();
	};
	ws.onopen = () => {
		console.log('WebSocket connection established');
		onopen();
	};
	ws.onmessage = (message) => callback(message);
	ws.onclose = () => {
		ws = null;
		console.log('WebSocket connection closed');
		onclose();
	};
}

export const closeConnection = () => {
	if (ws != null) {
		ws.close();
	}
}

export const sendMessageObject = async (messageObject = { sessionId: "100", type: "HISTORY" }) => {
	if (ws === null) {
		return;
	}
	ws.send(JSON.stringify(messageObject));
}

export const sendMessage = async (sessionId, messageText, owner) => {
	if (!sessionId || !messageText) {
		return;
	}
	// parse stream message
	// message = JSON.parse(message);
	if (ws === null) {
		return;
	}

	// let streamObject = {
	// 	lastQueueId: null,
	// 	currentGame: 0,
	// 	games: [newGameObject(0)],
	// 	stream: stream.channel,
	// 	gameType: stream.type
	// };
	// currentStreams.push(streamObject);

	let messageObject = {
		sessionId: sessionId,
		type: "MESSAGE",
		message: messageText,
		owner: owner,
		sentAt: new Date().toISOString()
	}
	ws.send(JSON.stringify(messageObject));
}

// export const getSentStreams = () => {
// 	return currentStreams;
// }