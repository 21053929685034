import React from 'react';
import { Modal, StyleSheet, ScrollView, View } from 'react-native';
import { Overlay } from '@rneui/themed';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { BottomViewButton, ViewHeader } from '../../components';

export default (props) => {
    return (
        <Overlay
            isVisible={props.isVisible}
            overlayStyle={{ padding: 0, ...props.overrideOverlayStyle }}
            fullScreen={true}
            // onPressIn={() => { return; }}
            // onBackdropPress={props.onExit}
            ModalComponent={Modal}
            animationType="slide"
        >
            <SafeAreaProvider>
                <ViewHeader
                    headerText={props.headerText}
                    onExit={props.onExit}
                />
                <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
                    {props.children}
                </ScrollView>

                <View style={{ marginBottom: 30 }}>
                    {props.ButtonComponent || <BottomViewButton title={props.buttonTitle} onPress={props.buttonOnPress} />}
                </View>
            </SafeAreaProvider>
        </Overlay>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        width: '100%',
        height: '100%',
        padding: 10
    }
});

