import React, { useEffect } from 'react';
import { Button, Icon, Input, Text } from '@rneui/themed';
import Toast from 'react-native-toast-message';
import { ViewContainer } from './containers';
import { SelectGameDropDown } from '../components';
import { viewStyles, sharedButtonStyles } from '../styles';
import { config as toastConfig } from '../../../config/toastConfig'
import { appApi } from '../../../services/api';
import { AuthStorage } from '../../../services/storage/auth-storage';

export default (props) => {
    const [email, setEmail] = React.useState();
    const [gameProfileText, setGameProfileText] = React.useState('');
    const [gameProfiles, setGameProfiles] = React.useState();
    const [gameSelection, setGameSelection] = React.useState('');
    AuthStorage.getUserEmail().then((value) => {
        // @ts-ignore
        setEmail(value);
    });

    useEffect(() => {
        const fetchProfile = async () => {
            const profile = await appApi.getUserProfile();
            setGameProfiles(profile.gameProfiles);
        }

        fetchProfile();
    }, []);

    const handleLogout = async () => {
        await AuthStorage.logout();
        props.onLogout();
    }

    const handleGameSelection = (value) => {
        setGameSelection(value);
        if (gameProfiles != null) {
            if (value === 'Clash Royale') {
                setGameProfileText(gameProfiles['clashRoyale'] || '');
            }

            if (value === 'Halo Infinite') {
                setGameProfileText(gameProfiles['haloInfinite'] || '');
            }
        }
    }

    const handleSave = async () => {
        const gameProfilesObj = gameProfiles ? gameProfiles : {};
        if (gameSelection === 'Clash Royale') {
            if (!(await appApi.checkGamerTagValid('clashroyale', gameProfileText))) {
                console.log("UMM THE FUCK")
                Toast.show({ type: 'error', text1: 'Invalid Gamer Tag.' });
                return;
            }
            gameProfilesObj.clashRoyale = gameProfileText;
        }

        if (gameSelection === 'Halo Infinite') {
            gameProfilesObj.haloInfinite = gameProfileText;
        }

        await appApi.updateUserGameProfiles(gameProfilesObj);
        Toast.show({ type: 'success', text1: 'Update Success.' });
    }

    return (
        <ViewContainer
            isVisible={props.isVisible}
            onExit={() => { setGameSelection(''); props.onExit() }}
            headerText={'User Profile'}
            buttonTitle={'Logout'}
            buttonOnPress={handleLogout}
        >
            <Text
                style={viewStyles.labelViewStyle}>
                Current Assigned Email:
            </Text>
            <Input
                placeholder={'Email'}
                rightIcon={<Icon
                    name='user'
                    type='font-awesome'
                    color='black'
                />}
                value={email}
                disabled={true}
            // onChangeText={setEmail}
            />

            <SelectGameDropDown
                labelText="Store Game Profiles:"
                setSelected={(val) => handleGameSelection(val)}
            />

            {!!gameSelection && <>
                <Input
                    placeholder={`${gameSelection} Gamer Tag`}
                    value={gameProfileText}
                    onChangeText={setGameProfileText}
                />
                <Button buttonStyle={sharedButtonStyles.buttonStyle} radius={'sm'} type="solid" onPress={handleSave}>
                    Save
                    <Icon name="save" color="white" />
                </Button>
            </>
            }
            {/* Has to be at bottom */}
            <Toast config={toastConfig} />
        </ViewContainer>
    );
};

