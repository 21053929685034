import React from 'react';
import { Button } from '@rneui/themed';
import { createBetStyles, sharedRoundBoarderStyle, bottomViewButtonStyle } from '../styles';

export default (props) => {
    return (<Button
        // type="outline"
        iconRight={true}
        title={props.title}
        titleStyle={{ color: 'white' }}
        onPress={props.onPress}
        containerStyle={{ ...createBetStyles.createBetSubmitButtonContainerStyle, ...sharedRoundBoarderStyle }}
        buttonStyle={bottomViewButtonStyle}
    />);
};