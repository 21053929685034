import React from 'react';
import { StyleSheet, Modal } from 'react-native';
import { Button, Input, Text, Overlay } from '@rneui/themed';

export default (props) => {
    return (<Overlay
        overlayStyle={{ width: '70%', }}
        ModalComponent={Modal}
        isVisible={props.isVisible}
        backdropStyle={{}}
        onBackdropPress={props.onBackdropPress}>
        <Text h4 style={{ alignSelf: "center" }}>Verify Access Code</Text>
        <Input
            style={{
                borderColor: "black",
                width: "100%"
            }}
            placeholder='Code'
            value={props.value}
            onChangeText={props.onChangeText}
        />
        <Button
            titleStyle={{
                color: "black",
                fontSize: 20
            }}
            buttonStyle={{
                backgroundColor: 'teal',
                borderRadius: 3,
            }}
            style={styles.verifyModalButton}
            size='md'
            onPress={props.onPress}>
            <Text style={{ color: "white" }}>Verify</Text>
        </Button>
    </Overlay>);
};

const styles = StyleSheet.create({
    verifyModalButton: {
        width: "100%",
        borderColor: "black",
    }
});