import React from 'react';
import ViewInnerContainer from './ViewInnerContainer';
import ViewOutterContainer from './ViewOutterContainer';

export default (props) => {
    return (
        <>
            <ViewOutterContainer
                overlayStyleOverrides={props.overlayStyleOverrides || undefined}
                isVisible={props.isVisible}
                onPressIn={props.onPressIn}
                onBackdropPress={props.onBackdropPress}
            >
                <ViewInnerContainer
                    headerText={props.headerText}
                    onExit={props.onExit}
                >
                    {props.children}
                </ViewInnerContainer>

                {props.bottomControlComponents}
            </ViewOutterContainer>
        </>
    )
}