import React from 'react';
import { View } from 'react-native';
import { Card } from '@rneui/themed';
import BetCardHeader from './BetCardHeader';
import BetCardDetails from './BetCardDetails';
import BetCardButton from './BetCardButton';
import { activeBetButtonStyles, cardStyles, sharedBackgroundColorButtonStyles } from '../../styles';

export default (props) => {
    return (
        <Card containerStyle={cardStyles.activeHistoryBetCardContainerSyle}>
            <BetCardHeader title={props.title} />
            <BetCardDetails
                game={props.game}
                type={props.type}
                amount={props.amount}
                privacy={props.privacy}
                created={props.created}
            />
            {/* TODO: add icon to card button and support */}
            <View style={{ flexDirection: 'row' }}>
                <BetCardButton
                    size={'sm'}
                    title="Delete"
                    onPress={props.onDelete}
                    containerStyle={activeBetButtonStyles.containerStyle}
                    buttonStyle={{
                        backgroundColor: 'red',
                        borderColor: 'red'
                    }}
                    titleStyle={{ fontSize: 14 }}
                    disabled={props.deleteDisabled}
                />
                <View style={{ marginRight: 4 }} />
                <BetCardButton
                    size={'sm'}
                    title="Share"
                    onPress={props.onShare}
                    containerStyle={activeBetButtonStyles.containerStyle}
                    buttonStyle={activeBetButtonStyles.buttonStyle}
                    titleStyle={{ fontSize: 14 }} />
            </View>
            <BetCardButton
                size={'sm'}
                title="Chat"
                onPress={props.onChat}
                containerStyle={{ ...activeBetButtonStyles.containerStyle, width: '100%' }}
                buttonStyle={{ ...activeBetButtonStyles.buttonStyle, ...sharedBackgroundColorButtonStyles }}
                titleStyle={{ fontSize: 14 }} />
        </Card>
    );
}