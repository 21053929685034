import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
// TODO: check if token is still valid if not get another get it from api
// and store it.
export class AuthStorage {
    static getAuthToken = async () => {
        let token = await AsyncStorage.getItem('AuthToken');

        // TODO: there is backend work to respond with appropriate expiration etc.
        // let tokenDecode = jwtDecode(token);

        // // check if expired
        // const now = new Date();
        // let isExpired = now.getTime() > tokenDecode.exp * 1000;

        // if (token == null || token == '' || isExpired) {
        //     // TODO: try to refresh it
        //     console.log("IS EXPIRED:", isExpired, tokenDecode.exp)
        // }
        return token;
    };
    static setAuthToken = async (token) => {
        let setItem = await AsyncStorage.setItem('AuthToken', token);
        return setItem;
    };

    static getUserEmail = async () => {
        let email = await AsyncStorage.getItem('UserEmail');

        if (!email) {
            console.log("NO EMAIL:")
        }
        return email;
    };

    static getUserProfile = async () => {
        let profile = await AsyncStorage.getItem('UserProfile');

        if (!profile) {
            console.log("NO PROFILE:")
        }
        // @ts-ignore
        return JSON.parse(profile);
    };

    static setUserEmail = async (email) => {
        return await AsyncStorage.setItem('UserEmail', email);
    }

    static setUserProfile = async (profile) => {
        return await AsyncStorage.setItem('UserProfile', JSON.stringify(profile));
    }

    static logout = async () => {
        await AsyncStorage.clear()
    }
}