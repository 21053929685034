import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Icon } from '@rneui/themed';
import Toast from 'react-native-toast-message';
import { ModalViewContainer } from './containers';
import { SelectGameDropDown } from '../components';
import { createBetStyles, filterButtonStyles } from '../styles';
import selectGameOptionData from '../data/selectGameOptionData';
import { PRIMARY_BLACK_COLOR } from '../../../global/styles';
import { config as toastConfig } from '../../../config/toastConfig';
import { AppStorage } from '../../../services/storage/app-storage';
const { SELECT_GAME_DATA } = selectGameOptionData;

export default (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [gameSelection, setGameSelection] = React.useState("");
    const [savedSelection, setSavedSelection] = useState(undefined);

    // TODO: error here need to handle unmount and mount appropraitely have to reload app for this to work.
    useEffect(() => {
        (async () => {
            const filters = await AppStorage.getFilters();
            for (let item of SELECT_GAME_DATA) {
                if (filters.game === item.value.toLowerCase().replace(' ', '')) {
                    // @ts-ignore
                    setSavedSelection(item);
                }
            }
        })();
    }, [savedSelection]);

    const setFilter = async (reset = false) => {
        try {
            if (!!reset) {
                await AppStorage.setFilters(null);
                setSavedSelection(undefined);
                return;
            }
            const filter = { game: gameSelection?.toLowerCase().replace(' ', '') };
            await AppStorage.setFilters(filter);
            // @ts-ignore
            setSavedSelection(filter);
        } catch (e) {
            console.log('filter set failure', e);
        } finally {
            await props.onFilterApplied();
        }
        return;
    }

    return (
        <>
            {/* TODO: refactor the use of this container (mainly making more abstractions within this container itself) */}
            <ModalViewContainer
                headerText='Filter Options'
                isVisible={props.isVisible}
                onPressIn={() => { return; }}
                onBackdropPress={props.onBackdropPress}
                onExit={props.onExit}
                bottomButtonOptions={{
                    iconName: 'filter',
                    title: 'Apply',
                    loading: isLoading,
                    onPress: () => { }
                }}
                overlayStyleOverrides={{
                    height: null
                }}
                bottomControlComponents={
                    <View style={{ ...createBetStyles.createBetSubmitButtonViewStyle, flexDirection: 'row' }}>
                        <Button
                            icon={
                                <Icon
                                    name={"undo"}
                                    type="font-awesome"
                                    color="white"
                                    size={15}
                                    iconStyle={{ marginLeft: 10 }}
                                />
                            }
                            iconRight={true}
                            title="Reset"
                            onPress={props.onDelete}
                            containerStyle={{ ...filterButtonStyles.containerStyle, paddingRight: 5 }}
                            buttonStyle={{ ...filterButtonStyles.buttonStyle, backgroundColor: 'red', borderColor: 'red' }}
                            titleStyle={{ fontSize: 13 }}
                            disabled={props.deleteDisabled}
                            onPress={() => { setFilter(true); props.onExit(); }}
                        />
                        <Button
                            icon={
                                <Icon
                                    name={"filter"}
                                    type="font-awesome"
                                    color="white"
                                    size={15}
                                    iconStyle={{ marginLeft: 10 }}
                                />
                            }
                            iconRight={true}
                            title="Apply"
                            onPress={props.onShare}
                            containerStyle={{ ...filterButtonStyles.containerStyle, paddingLeft: 5 }}
                            buttonStyle={{ ...filterButtonStyles.buttonStyle, borderColor: PRIMARY_BLACK_COLOR, backgroundColor: PRIMARY_BLACK_COLOR }}
                            titleStyle={{ fontSize: 13 }}
                            onPress={() => { setFilter(); props.onExit(); }}
                        />
                    </View>
                }
            >
                <SelectGameDropDown
                    labelText={'Game Filter:'}
                    setSelected={(val) => setGameSelection(val)}
                    defaultOption={savedSelection}
                />
                {/* Has to be at bottom */}
                <Toast config={toastConfig} />
            </ModalViewContainer>
        </>
    )
}