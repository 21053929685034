import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { View, Animated } from 'react-native';
import { Button } from '@rneui/themed';
import { Divider } from '@rneui/base';

export default (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const animationHeight = useRef(new Animated.Value(0)).current;
    const [childrenHeight, setChildrenHeight] = useState(0);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const collapseView = () => {
        Animated.timing(animationHeight, {
            duration: 500,
            toValue: 0,
            useNativeDriver: false
        }).start();
    }

    const expandView = () => {
        Animated.timing(animationHeight, {
            duration: 500,
            toValue: childrenHeight,
            useNativeDriver: false
        }).start();
    }

    useEffect(() => {
        if (collapsed) {
            collapseView();
        } else {
            expandView();
        }
    }, [collapsed]);

    return (
        <View style={{ overflow: 'hidden' }} onLayout={(event) => {
            // TODO: make this better
            const { height } = event.nativeEvent.layout;
            // console.log(height)
            setChildrenHeight(height + (props.paddedCollapseHeight || 25))
        }}>
            <Animated.View style={{ height: animationHeight }}>
                {!collapsed && props.children}
            </Animated.View>
            <Divider style={{ marginTop: !!collapsed ? 10 : 0 }}></Divider>
            <Button
                size='sm'
                color={'transparent'}
                titleStyle={{ fontSize: 13, margin: 0, color: "black", textAlign: 'center', alignSelf: 'center' }}
                title={!!collapsed ? "Show Details +" : "Hide Details -"}
                onPress={toggleCollapsed} />
        </View>
    );
}