import React from 'react';
import { Card } from '@rneui/themed';
import BetCardHeader from './BetCardHeader';
import BetRowDetail from './BetRowDetail';
import BetRowDateDetail from './BetRowDateDetail';
import BetCardDetails from './BetCardDetails';
import CollapseView from '../CollapseView';
import { cardStyles } from '../../styles';

export default (props) => {
    return (
        <Card containerStyle={cardStyles.activeHistoryBetCardContainerSyle}>
            <BetCardHeader
                title={props.title}
            />
            <BetCardDetails
                game={props.game}
                type={props.type}
                amount={props.amount}
                privacy={props.privacy}
                created={props.created}
            />
            <BetRowDetail leftText='Team:' rightText={props.team} />
            {/* TODO: make this much better */}
            <BetRowDetail leftText='Winner:' rightText={props.gameResult.team1 ? "Team 1" : "Team 2"} />
            <CollapseView>
                <BetRowDetail leftText='Record:' rightText={props.record} />
                <BetRowDateDetail leftText='Ended Date:' date={props.endDate} />
                <BetRowDetail leftText='Bet Id:' rightText={props.id} />
            </CollapseView>
        </Card>
    );
}