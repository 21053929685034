import { View } from 'react-native';
import { Text } from '@rneui/themed';
import { BaseToast, ErrorToast, SuccessToast } from 'react-native-toast-message';

const text1Style = {
    textAlign: 'center',
    fontSize: 15,
    fontWeight: '400'
}
const text2Style = {
    textAlign: 'center',
    fontSize: 13
}

const contentContainerStyle = {
    paddingHorizontal: 15
}

const styleBase = {
    borderLeftWidth: 10
}

/*
  1. Create the config
*/
const config = {
    /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
    success_pink: (props) => (
        <BaseToast
            {...props}
            style={{ ...styleBase, borderLeftColor: 'pink' }}
            contentContainerStyle={contentContainerStyle}
            text1Style={text1Style}
            text2Style={text2Style}
        />
    ),

    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    warn: (props) => (
        <BaseToast
            {...props}
            style={{ ...styleBase, borderLeftColor: 'yellow' }}
            contentContainerStyle={contentContainerStyle}
            text1Style={text1Style}
            text2Style={text2Style}
        />
    ),

    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    success: (props) => (
        <SuccessToast
            {...props}
            style={{ ...styleBase, borderLeftColor: 'lightgreen' }}
            text1Style={text1Style}
            text2Style={text2Style}
        />
    ),

    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    error: (props) => (
        <ErrorToast
            {...props}
            style={{ ...styleBase, borderLeftColor: 'red' }}
            text1Style={text1Style}
            text2Style={text2Style}
        />
    ),
    /*
      Or create a completely new type - `tomatoToast`,
      building the layout from scratch.
  
      I can consume any custom `props` I want.
      They will be passed when calling the `show` method (see below)
    */
    tomatoToast: ({ text1, props }) => (
        <View style={{ height: 60, width: '100%', backgroundColor: 'tomato' }}>
            <Text>{text1}</Text>
            <Text>{props.uuid}</Text>
        </View>
    )
};

export {
    config
}