import React from 'react';
import { View, Image } from 'react-native';
import { Text } from '@rneui/themed';
import BetRowDetail from './BetRowDetail';
import BetRowDateDetail from './BetRowDateDetail';
import { getGameAssetIcon } from '../../../../services/tools';

export default (props) => {
    return (
        <View>
            <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                <BetRowDetail fontSize={props.fontSize || undefined} containerStyle={{ justifyContent: 'flex-start' }} leftText='Game:' rightText={props.game || 'Game Type Name Goes Here'} />
                <Image
                    style={{ width: 25, height: 25, justifyContent: 'flex-end' }}
                    resizeMode="cover"
                    source={getGameAssetIcon(props.game || "CR")}
                />
            </View>
            <BetRowDetail fontSize={props.fontSize || undefined} leftText='Type:' rightText={`${props.type}`.toUpperCase()} />
            {props.desc && <Text style={{ marginBottom: 10 }}>
                {props.desc}
            </Text>}

            <BetRowDetail fontSize={props.fontSize || undefined} leftText='Best Of:' rightText={props.bestOf || '1'} />
            <BetRowDetail fontSize={props.fontSize || undefined} icon='gg' leftText='Bet Amount:' rightText={props.amount} />
            <BetRowDetail fontSize={props.fontSize || undefined} leftText='Privacy:' rightText={props.privacy} />
            <BetRowDateDetail fontSize={props.fontSize || undefined} date={props.created} />
        </View>
    );
}