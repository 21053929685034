import React from 'react';
import { FlatList } from 'react-native';
import { Text } from '@rneui/themed';
import HistoryBetCard from '../Bet/HistoryBetCard';
import { realTestDataList } from '../../data';
import { listStyles } from '../../styles';

export default (props) => {
    const renderRow = ({ item }) => {
        // TODO: make this logic below cleaner and probably better display wise for record
        let countObj = { team1: 0, team2: 0 };
        if (item.bestOf && parseInt(item.bestOf) > 1) {
            for (let bestOfResult of item.bestOfResults) {
                if (bestOfResult.gameResult.team1.toString().toLowerCase() === "true") {
                    countObj.team1++;
                }
                if (bestOfResult.gameResult.team2.toString().toLowerCase() === "true") {
                    countObj.team2++;
                }
            }
        } else {
            if (item.gameResult.team1.toString().toLowerCase() === "true") {
                countObj.team1++;
            }
            if (item.gameResult.team2.toString().toLowerCase() === "true") {
                countObj.team2++;
            }
        }
        const record = `Team1: ${countObj.team1} - Team2: ${countObj.team2}`;
        console.log("RECORD:", record)
        return (
            <HistoryBetCard
                title={<>
                    <Text style={{ color: 'black' }}>Challenge:</Text>
                    <Text style={{}}> {item?.teams?.filter(x => x.isHost)[0].gameId}</Text>
                </>}
                game={item.game}
                type={`${item.type}`.toUpperCase()}
                amount={item.amount}
                // desc="Test Description"
                privacy={item.privacy}
                created={item.created}
                endDate={item.endSessionDate}
                id={item.id}
                team={item?.teams?.find(x => x.id === item.username)?.teamId}
                gameResult={item.gameResult}
                record={record}
            />
        );
    };

    return (
        <FlatList
            style={listStyles.defaultListStyleProps}
            ListEmptyComponent={<Text style={{ textAlign: 'center' }}>No Bet History, Please Create One.</Text>}
            data={props.data || realTestDataList}
            keyExtractor={(a, index) => index.toString()}
            renderItem={renderRow}
            horizontal={false}
            showsVerticalScrollIndicator={false}
        />
    )
}