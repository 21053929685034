export default {
    testMessages1: [
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        }
    ],

    testMessages2: [
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
        {
            index: 1,
            // message: "Hello world",
            receiverId: 'CurrentUser',
            name: "Test Name",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 1"
        },
        {
            index: 2,
            // message: "Hello world",
            receiverId: 'CurrentUser2',
            name: "Test Name 2",
            sentAt: new Date(),
            sender: { name: "TEST SENDER NAME 2", avatar: 'https://cdn-icons-png.flaticon.com/512/147/147144.png', },
            text: "Hello world message text 2"
        },
    ]
}