import React from 'react';
import { Modal, View } from 'react-native';
import { SafeAreaProvider, useSafeAreaInsets } from 'react-native-safe-area-context';
import { ViewHeader } from '../../components';
import MessageContainer from './MessageContainer';

export const Chat = (props) => {
    const insets = useSafeAreaInsets();
    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={props.isVisible}
            onRequestClose={props.onExit}
        >
            <SafeAreaProvider>
                <ViewHeader
                    headerText={props.headerText || "Game Chat"}
                    onExit={props.onExit}
                />
                <View style={{ flex: 1, paddingBottom: insets.bottom, backgroundColor: 'white' }} >
                    <MessageContainer {...props} />
                </View>
            </SafeAreaProvider>
        </Modal>
    )
}