import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Modal, TouchableWithoutFeedback } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Header as CustomRNEHeader } from '../components/Header';
import Lists from './Lists';
import FloatingFab from './FloatingFab';
import Tabs from './Tabs';
import { appApi } from '../../../services/api';
import { OFFSET_WHITE_COLOR } from '../../../global/styles';

export default (props) => {
    const insets = useSafeAreaInsets();

    const [isVisible, setIsVisible] = useState(false);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [activeBetListData, setActiveBetListData] = React.useState(null);
    const [historyBetListData, setHistoryBetListData] = React.useState(null);

    // fetch data
    const fetchBetData = async () => {
        try {
            const activeBets = await appApi.getUserBets();
            // @ts-ignore
            const historyBets = await appApi.getUserBets('history');

            // set state when the data received
            setActiveBetListData(activeBets.Items);
            setHistoryBetListData(historyBets.Items);
        } catch (e) {
            console.log("API FAILURE:", e);
        }
    };

    useEffect(() => {
        fetchBetData();
    }, []);

    const handleOnFabPress = () => {
        if (!isVisible) {
            fetchBetData();
        }
        setIsVisible(!isVisible);
    }

    return (<>
        <Modal
            animationType="slide"
            transparent={true}
            visible={isVisible}
            onRequestClose={() => { setIsVisible(false) }}
        >
            <TouchableWithoutFeedback onPress={() => { setIsVisible(false) }}>
                <View style={embeddedStyles.modalOverlay} />
            </TouchableWithoutFeedback>
            <View
                style={{
                    height: '65%',
                    marginTop: 'auto',
                    backgroundColor: OFFSET_WHITE_COLOR,
                    ...embeddedStyles.boarderStyle
                }}>

                <CustomRNEHeader
                    edges={['left', 'right']}
                    backgroundColor={OFFSET_WHITE_COLOR}
                    containerStyle={embeddedStyles.boarderStyle}
                    centerComponent={{ text: 'My Bets', style: embeddedStyles.heading }}
                />
                <Tabs tabItems={['Active', 'History']} value={tabIndex} onChange={setTabIndex} />
                <View style={{ flex: 1, paddingBottom: insets.bottom, backgroundColor: OFFSET_WHITE_COLOR }} >
                    {/* TODO: add loading around these lists */}
                    {tabIndex === 0 && <Lists.ActiveBetList data={activeBetListData} onRefresh={fetchBetData} />}
                    {tabIndex === 1 && <Lists.HistoryBetList data={historyBetListData} onRefresh={fetchBetData} />}
                </View>
            </View>
        </Modal>
        {
            props.isFabActive && <FloatingFab
                onPress={handleOnFabPress}
            />
        }
    </>);
};

const embeddedStyles = StyleSheet.create({
    heading: {
        color: 'teal',
        fontSize: 20,
        // fontWeight: 'bold',
    },
    tabIndicator: {
        backgroundColor: 'teal'
    },
    tabItem: {
        color: 'teal'
    },
    modalOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    boarderStyle: {
        borderTopStartRadius: 20,
        borderTopEndRadius: 20,
        borderTopWidth: 2,
        borderColor: 'transparent'
    }
});