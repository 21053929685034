import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Avatar, Text } from '@rneui/themed';
import messageHelper from './messageHelper'

export default ({ message, currentUser, owner }) => {
    return currentUser !== owner ? (
        <View style={[styles.flexify, styles.spaceMsg]}>
            <Avatar
                // placeholderStyle={{ opacity: 0 }}
                rounded
                // source={{ uri: message.sender.avatar }}
                size={60}
                icon={{ type: 'font-awesome', name: 'user', color: 'black' }}
            />

            <View style={[styles.msgBg, { marginLeft: 10 }]}>
                <Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: 13,
                        color: '#4c4c4c',
                        // textTransform: 'capitalize',
                    }}
                >
                    {message.sender.name}
                </Text>
                <Text style={{ fontWeight: '600', marginVertical: 5 }}>
                    {message.text}
                </Text>
                <Text style={{ fontWeight: '600' }}>
                    {messageHelper.dateToTime(new Date(message.sentAt))}
                </Text>
            </View>
        </View>
    ) : (
        <View style={[styles.flexify, styles.spaceMsg]}>
            <View
                style={[styles.msgBg, { backgroundColor: '#c5c5c5', /* marginRight: 10 */ }]}
            >
                <Text
                    style={{
                        fontWeight: 'bold',
                        fontSize: 13,
                        color: '#4c4c4c',
                        // textTransform: 'capitalize',
                    }}
                >
                    {message.sender.name}
                </Text>
                <Text styles={{ fontWeight: '600', marginVertical: 5 }}>
                    {message.text}
                </Text>
                <Text style={{ textAlign: 'right', fontSize: 12 }}>
                    {messageHelper.dateToTime(new Date(message.sentAt))}
                </Text>
            </View>

            <Avatar
                // placeholderStyle={{ opacity: 0 }}
                rounded
                // source={{ uri: message.sender.avatar }}
                size={60}
                icon={{ type: 'font-awesome', name: 'user', color: 'teal' }}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#122643',
    },
    flexify: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    msgBg: {
        flex: 1,
        backgroundColor: '#efefef',
        borderRadius: 20,
        padding: 10,
    },
    spaceMsg: {
        alignItems: 'flex-end',
        marginVertical: 5,
    },
    shadow: {
        shadowColor: '#171717',
        shadowOffsetWidth: 0,
        shadowOffsetHeight: 2,
        shadowOpacity: 0.2,
        shadowRadius: 3,
        backgroundColor: 'white',
    },
    positAtBottom: {
        // position: 'absolute',  
        left: 0,
        right: 0,
        bottom: 0,
        paddingHorizontal: 15,
        paddingVertical: 15,
    },
})
