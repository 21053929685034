import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { renderNode } from './helpers';
import { Icon } from '@rneui/themed';

const ALIGN_STYLE = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
};

// type Placement = 'left' | 'center' | 'right';

// export interface HeaderChildrenProps {
//     placement: Placement;
//     style: StyleProp<ViewStyle>;
//     children: any;
// }

export const Children = ({
    style,
    placement,
    children,
}) => (
    <View
        style={StyleSheet.flatten([{ alignItems: ALIGN_STYLE[placement] }, style])}
    >
        {children == null || children === false
            ? null
            : children.text
                ? renderNode(Text, children.text, { numberOfLines: 1, ...children })
                : children.icon
                    ? renderNode(Icon, {
                        ...children,
                        name: children.icon,
                        containerStyle: StyleSheet.flatten([
                            { alignItems: ALIGN_STYLE[placement] },
                            children.containerStyle,
                        ]),
                    })
                    : renderNode(Text, children)}
    </View>
);