const dateToTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const isDateToday = (date) => {
    const today = new Date().getDate();
    const day = new Date(date * 1000).getDate();

    return today == day;
}

export default {
    dateToTime,
    isDateToday
}