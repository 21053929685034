import React, { useEffect } from 'react';
import { Input, Text } from '@rneui/themed';
import Toast from 'react-native-toast-message';
import { ViewContainer } from './containers';
import { viewStyles } from '../styles';
import { appApi } from '../../../services/api';
import { AppStorage } from '../../../services/storage/app-storage';
import { config as toastConfig } from '../../../config/toastConfig'

export default (props) => {
    const [currentBalance, setCurrentBalance] = React.useState('N/A');
    const [publicKey, setPublicKey] = React.useState('');
    const [secretKey, setSecretKey] = React.useState('');

    const storageDataFetch = async () => {
        const result = await AppStorage.getCurrentWalletByType();
        if (!!result) {
            setPublicKey(result.pk);
            setSecretKey(result.sk);
        }
    }

    const dataFetch = async () => {
        try {
            await storageDataFetch();
            const data = await appApi.getWalletBalance('stellar', publicKey);

            // set state when the data received
            setCurrentBalance(data.balance);
        } catch (e) {
            console.log("API FAILURE:", e);
        }
    };

    const handleOnSave = async () => {
        await AppStorage.setWallets([{
            type: 'stellar',
            pk: publicKey,
            sk: secretKey
        }]);
        Toast.show({ type: 'success', text1: 'Success' });
        dataFetch();
    }

    useEffect(() => {
        // fetch data
        dataFetch();
    }, [publicKey]);

    return (
        <ViewContainer
            isVisible={props.isVisible}
            onExit={props.onExit}
            headerText={'Wallets'}
            buttonTitle={'Save'}
            buttonOnPress={handleOnSave}
        >
            <Text
                style={{ ...viewStyles.labelViewStyle, alignSelf: 'center' }}>
                Disclaimer
            </Text>
            <Text style={{ fontSize: 14, color: '#000', textAlign: 'left', marginBottom: 10 }}>This wallet address and key are stored locally and securely. We do not use or store this on our servers.
                This is for signing through the stellar SDK, and to send funds paid out to this address by games played. Ideally this wallet is HOT and managed by you for funds to be played with
                and withdrawn, completely out of our control. If you have questions please contact <Text style={{ fontWeight: 'bold', textDecorationLine: 'underline' }}>support@ewager.online</Text></Text>
            <Text>
                <Text
                    style={viewStyles.labelViewStyle}>
                    Current Account Balance:
                </Text>
                <Text style={{ color: "black", marginLeft: 10, textDecorationLine: 'underline' }}>
                    {currentBalance}
                </Text>
            </Text>
            <Text
                style={viewStyles.labelViewStyle}>
                Current Stellar PK:
            </Text>
            <Input
                placeholder={'XXXX'}
                value={publicKey}
                // disabled={true}
                onChangeText={setPublicKey}
            />
            <Text
                style={viewStyles.labelViewStyle}>
                Current Stellar SK:
            </Text>
            <Input
                placeholder={'XXXX'}
                value={secretKey}
                secureTextEntry={true}
                // disabled={true}
                onChangeText={setSecretKey}
            />
            <Toast config={toastConfig} />
        </ViewContainer>
    );
};

