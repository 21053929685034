import React from 'react';
import Toast from 'react-native-toast-message';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { LoginScreen, MainScreen } from '../screens'
import { config } from '../config/toastConfig';
import { AppLoading } from './expo-loader';
// import { StatusBar } from '../components/StatusBar';
import { SplashImage } from './SplashImage';

const Splash = ({ loading }) => (
    <SplashImage
        loading={loading}
        source={require('../../assets/new-splash-black.png')}
    />
);

const loadingTasks = [
    // Should be used it when running Expo.
    // In Bare RN Project this is configured by react-native.config.js
    // () => LoadFontsTask({
    //     'opensans-regular': require('../../assets/fonts/opensans-regular.ttf'),
    //     'roboto-regular': require('../../assets/fonts/roboto-regular.ttf'),
    // }),
];

const Stack = createNativeStackNavigator();
const App = (props) => {
    return (
        <React.Fragment>
            <SafeAreaProvider>
                <StatusBar />
                <NavigationContainer>
                    <Stack.Navigator>
                        <Stack.Screen name="LoginNavigator" component={LoginScreen} options={{ headerShown: false }} />
                        <Stack.Screen name="MainNavigator" component={MainScreen} options={{ headerShown: false }} />
                    </Stack.Navigator>
                </NavigationContainer>
                <Toast config={config} />
            </SafeAreaProvider>
        </React.Fragment>
    );
}

export default () => (
    <AppLoading
        tasks={loadingTasks}
        initialConfig={{}}
        placeholder={Splash}>
        {props => <App {...props} />}
    </AppLoading>
);
