import { LogBox } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * @todo: https://docs.expo.dev/versions/latest/sdk/securestore/ upgrade to this for security of sensastive items
 */
export class AppStorage {

    static getCurrentWalletByType = async (type = 'stellar') => {
        try {
            const results = await AppStorage.getWallets();
            return results.filter(x => x.type === type)[0];
        } catch (e) { }
        return null;
    };

    static getWallets = async () => {
        try {
            // @ts-ignore
            return JSON.parse(await AsyncStorage.getItem('Wallets'));
        } catch (e) { }
        return [];
    };

    static setWallets = async (wallets) => {
        return await AsyncStorage.setItem('Wallets', JSON.stringify(wallets));
    };

    static getFilters = async () => {
        try {
            // @ts-ignore
            return JSON.parse(await AsyncStorage.getItem('filters'));
        } catch (e) { }
        return null;
    }

    static setFilters = async (filters) => {
        if (filters == null) {
            return await AsyncStorage.removeItem('filters');
        }
        return await AsyncStorage.setItem('filters', JSON.stringify(filters));
    }
}

/**
 * In a Bare React Native project you should use
 * https://github.com/react-native-community/async-storage
 *
 * However, Expo runs AsyncStorage exported from react-native.
 * Just to save application bundle size, we still using this one.
 */
LogBox.ignoreLogs(['AsyncStorage has been extracted']);