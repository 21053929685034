import React from 'react';
import { Input } from '@rneui/themed';
import { LabelText } from '../components';

export default (props) => {
    return (
        <>
            <LabelText text='Enter Game Username:' />
            <Input
                placeholder="username or tag"
                value={props.value}
                onChangeText={props.onChangeText}
            />
        </>
    )
}
