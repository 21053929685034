import React from 'react';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { Asset } from 'expo-asset';

// type TaskResult = [string, any];
// type Task = () => Promise<TaskResult | null>;

// export interface ApplicationLoaderProps {
//   tasks?: Task[];
//   initialConfig?: Record<string, any>;
//   placeholder?: (props: { loading: boolean }) => React.ReactElement;
//   children: (config: any) => React.ReactElement;
// }

export const LoadFontsTask = (fonts) => {
    return Font.loadAsync(fonts).then(() => null);
};

export const LoadAssetsTask = (assets) => {
    const tasks = assets.map((source) => {
        return Asset.fromModule(source).downloadAsync();
    });

    return Promise.all(tasks).then(() => null);
};

/*
 * Prevent splash screen from hiding since it is controllable by AppLoading component.
 */
SplashScreen.preventAutoHideAsync();

/**
 * Loads application configuration and returns content of the application when done.
 *
 * @property {Task[]} tasks - Array of tasks to prepare application before it's loaded.
 * A single task should return a Promise with value and a by which this value is accessible.
 *
 * @property {any} fallback - Fallback configuration that is used as default application configuration.
 * May be useful at first run.
 *
 * @property {(props: { loaded: boolean }) => React.ReactElement} placeholder - Element to render
 * while application is loading.
 *
 * @property {(result: any) => React.ReactElement} children - Should return Application component
 */
export const AppLoading = (props) => {
    const [loading, setLoading] = React.useState(true);
    const loadingResult = props.initialConfig || {};

    React.useEffect(() => {
        async function prepare() {
            try {
                // Pre-load fonts, make any API calls you need to do here
                // await tasks that need to be completed
                await startTasks();
            } catch (e) {
                await onError();
            } finally {
                // Tell the application to render
                // setAppIsReady(true);
                await onTasksFinish();
            }
        }

        prepare();
    }, []);

    const onTasksFinish = () => {
        setLoading(false);
        SplashScreen.hideAsync();
    };

    const saveTaskResult = (result) => {
        if (result) {
            loadingResult[result[0]] = result[1];
        }
    };

    const createRunnableTask = (task) => {
        return task().then(saveTaskResult);
    };

    const startTasks = () => {
        if (props.tasks) {
            return Promise.all(props.tasks.map(createRunnableTask));
        }
        return Promise.resolve();
    };

    const onError = () => {
        console.log("ERROR");
        return Promise.resolve();
    };

    return (
        <React.Fragment>
            {loading ? <React.Fragment /> : props.children(loadingResult)}
            {props.placeholder && props.placeholder({ loading })}
        </React.Fragment>
    );
};