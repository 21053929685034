import React, { useEffect } from 'react';
import Toast from 'react-native-toast-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { BottomDrawer, Lists, MainScreenHeader, Tabs } from './components';
import { StaticOptions } from './views';
import { appApi } from '../../services/api';

export default ({ navigation }) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const [betListData, setBetListData] = React.useState(null);

    // fetch data
    const fetchBetData = async () => {
        try {
            const data = await appApi.getBets();

            // set state when the data received
            setBetListData(data.Items);
        } catch (e) {
            console.log("API FAILURE:", e);
        }
    };

    useEffect(() => {
        fetchBetData();
    }, []);

    const handleTabChange = (value) => {
        if (value > 0) {
            Toast.show({ type: 'warn', text1: 'Coming Soon' });
            return;
        }
        setTabIndex(value);
    }

    return (
        <SafeAreaProvider>
            <MainScreenHeader navigation={navigation} onPressLeftComponent={() => { /* this should lazy load, TODO: verify */fetchBetData() }} />
            <Tabs tabItems={['Pvp', 'Twitch']} value={tabIndex} onChange={handleTabChange} />

            {/* TODO: looking into the arch of this and whether we want this always static */}
            <StaticOptions onBetCreated={fetchBetData} onFilterApplied={fetchBetData} />
            <Lists.PvpList data={betListData} onRefresh={fetchBetData} />
            <BottomDrawer isFabActive onRefresh={fetchBetData} />
        </SafeAreaProvider>
    )
}