import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import Toast from 'react-native-toast-message';
import { Input, Divider, ButtonGroup } from '@rneui/themed';
import isMatch from 'date-fns/isMatch';
import { betSubmissionWalletCheck, fetchCurrentGameSelectionTag, fetchCurrentWallet } from './shared';
import { BetViewContainer } from './containers';
import { LabelText, SelectGameDropDown, GameUsernameInput } from '../components';
import { validator } from '../data';
import { config as toastConfig } from '../../../config/toastConfig'
import { appApi } from '../../../services/api';

const PLAY_TYPES = ['PVP', 'Team'];
const BEST_OF_OPTIONS = ['1', '3', '5', '7'];

export default (props) => {
    const TODAY = new Date();

    const getCurrentDataString = () => {
        let month = `${TODAY.getUTCMonth() + 1}`;
        if (month.toString().length < 2) {
            month = `0${month}`;
        }
        let day = `${TODAY.getUTCDate()}`;
        if (day.toString().length < 2) {
            day = `0${day}`;
        }
        return `${month}/${day}/${TODAY.getUTCFullYear()}`;
    }

    const [isLoading, setIsLoading] = useState(false);
    const [selectedButtonGroupIndex, setSelectedButtonGroupIndex] = useState(0);
    const [gameSelection, setGameSelection] = React.useState("");
    const [selectedButtonGroupBestOfIndex, setSelectedButtonGroupBestOfIndex] = useState(0);
    const [betAmount, setBetAmount] = useState(null);
    const [gameUsername, setGameUsername] = useState(null);
    const [date, setDate] = useState(getCurrentDataString());
    const [dateValid, setDateValid] = useState(false);
    const [publicWalletAddress, setPublicWalletAddress] = useState('');

    useEffect(() => {
        fetchCurrentWallet(setPublicWalletAddress);
    }, []);

    useEffect(() => {
        fetchCurrentGameSelectionTag(setGameUsername, gameSelection);
    }, [gameSelection]);

    useEffect(() => {
        try {
            // regex for MM-DD-YYYY
            const check = isMatch(date, 'MM/dd/yyyy');

            if (check) {
                const currentYear = new Date().getUTCFullYear();
                const yearValue = date.split('/')[2];

                if (yearValue.length < 4 || (parseInt(yearValue) < currentYear)) {
                    setDateValid(false);
                    return;
                }
                setDateValid(true);
                return;
            }
            setDateValid(false);

        } catch (e) {
            setDateValid(false);
        }
    }, [date]);

    const handleSelectedGameType = (value) => {
        if (value > 0) {
            Toast.show({ type: 'warn', text1: 'Coming Soon' });
            return;
        }
        setSelectedButtonGroupIndex(value);
    }

    const handleSelectedBestOf = (value) => {
        // if (value > 0) {
        //     Toast.show({ type: 'warn', text1: 'Coming Soon' });
        //     return;
        // }
        setSelectedButtonGroupBestOfIndex(value);
    }

    const handleCreatePressLoading = async () => {
        setIsLoading(true);
        await handleCreatePress();
        setIsLoading(false);
    }

    const handleCreatePress = async () => {
        const wallet = await betSubmissionWalletCheck(Toast, betAmount);
        if (!wallet) {
            return;
        }

        // build createBet
        const createBet = {
            // TODO: support more
            type: PLAY_TYPES[selectedButtonGroupIndex],
            game: gameSelection.toLowerCase().replace(/\s/g, '') || 'clashroyale',
            gameId: gameUsername,
            wallet: wallet.pk,
            filter: 'nofilter',
            openSessionDate: date,
            // TODO: manage this
            endSessionDate: date,
            bestOf: BEST_OF_OPTIONS[selectedButtonGroupBestOfIndex],
            amount: betAmount,
            password: null,
            privacy: 'public'
        }

        if (!validator.validateBet(createBet)) {
            Toast.show({ type: 'error', text1: 'Fill out all necessary items!' });
            return;
        }

        try {
            // build bet, create it and show success
            const createBetResult = await appApi.createBet(createBet);
            // TODO: amount needs to support more than one player type for funding, also other work on funding here in general this is POC idea
            const fundBetResult = await appApi.fundBet({ id: createBetResult.callbackId, pk: wallet.pk, sk: wallet.sk, amount: (betAmount / 2) });
        } catch (e) {
            Toast.show({ type: 'error', text1: 'Bet Create Failure, Try Again!' });
            return;
        } finally {
            setIsLoading(false);
        }

        // show success for a second
        Toast.show({ type: 'success', text1: 'Bet Created Successfully!' });

        // 1.5 seconds display success and then close
        await new Promise(res => setTimeout(res, 1500));
        props.onBetCreated();
        props.onExit();
    }

    const LabelDivider = () => {
        return (
            <Divider color='teal'
                style={{ marginTop: 10, marginBottom: 10 }}
            // could set right left etc
            // inset={true}
            // insetType="middle"
            />
        )
    }

    return (
        <>
            <BetViewContainer
                headerText='Create Bet'
                isVisible={props.isVisible}
                onPressIn={() => { return; }}
                onBackdropPress={props.onBackdropPress}
                onExit={props.onExit}
                bottomButtonOptions={{
                    iconName: 'send',
                    title: 'Create Bet',
                    loading: isLoading,
                    onPress: handleCreatePressLoading
                }}
            >

                <LabelText text='Select Play Type:' />
                <ButtonGroup
                    selectedTextStyle={{ color: 'white' }}
                    selectedButtonStyle={{ backgroundColor: 'teal' }}
                    buttons={PLAY_TYPES}
                    selectedIndex={selectedButtonGroupIndex}
                    onPress={handleSelectedGameType}
                />

                <SelectGameDropDown
                    setSelected={(val) => setGameSelection(val)}
                />

                <View style={{ marginTop: 5 }} />
                <LabelText text='Bet Amount (XLM):' />
                <Input
                    placeholder="100.00"
                    // leftIcon={{ type: 'font-awesome', name: 'comment' }}
                    value={betAmount}
                    onChangeText={setBetAmount}
                />

                <LabelText text='Best Of:' />
                <ButtonGroup
                    containerStyle={{ height: 35 }}
                    selectedTextStyle={{ color: 'white' }}
                    selectedButtonStyle={{ backgroundColor: 'teal' }}
                    buttons={BEST_OF_OPTIONS}
                    selectedIndex={selectedButtonGroupBestOfIndex}
                    onPress={handleSelectedBestOf}
                />

                <GameUsernameInput
                    value={gameUsername}
                    onChangeText={setGameUsername}
                />

                <LabelText text='Enter Desired Play Date (MM/DD/YYYY):' />
                <Input
                    placeholder="MM/DD/YYYY"
                    value={date}
                    onChangeText={setDate}
                    errorStyle={{ color: 'red' }}
                    errorMessage={!dateValid ? 'ERROR: enter valid date.' : null}
                />

                <LabelText text='Payout Wallet Public Address (XLM):' />
                <Input
                    placeholder="GBEHP2GSWQHMNMFQ6IUXZF5VBKEFJ5ABLLOIC44W6DS6JINBZVX3KLEX"
                    value={publicWalletAddress}
                    onChangeText={setPublicWalletAddress}
                />

                {/* TODO: do we want to allow title creation and or description creation? */}

                {/* Has to be at bottom */}
                <Toast config={toastConfig} />
            </BetViewContainer>
        </>
    )
}