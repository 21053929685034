const listTestData = [
    {
        name: 'Amy Farha',
        avatar_url: 'https://uifaces.co/our-content/donated/XdLjsJX_.jpg',
        subtitle: 'Vice President',
        linearGradientColors: ['#FF9800', '#F44336'],
    },
    {
        name: 'Chris Jackson',
        avatar_url: 'https://uifaces.co/our-content/donated/KtCFjlD4.jpg',
        subtitle: 'Vice Chairman',
        linearGradientColors: ['#3F51B5', '#2196F3'],
    },
    {
        name: 'Amanda Martin',
        avatar_url:
            'https://images.unsplash.com/photo-1498529605908-f357a9af7bf5?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=047fade70e80ebb22ac8f09c04872c40',
        subtitle: 'CEO',
        linearGradientColors: ['#FFD600', '#FF9800'],
    },
    {
        name: 'Christy Thomas',
        avatar_url: 'https://randomuser.me/api/portraits/women/48.jpg',
        subtitle: 'Lead Developer',
        linearGradientColors: ['#4CAF50', '#8BC34A'],
    },
    {
        name: 'Melissa Jones',
        avatar_url:
            'https://images-na.ssl-images-amazon.com/images/M/MV5BMTQwMDQ0NDk1OV5BMl5BanBnXkFtZTcwNDcxOTExNg@@._V1_UY256_CR2,0,172,256_AL_.jpg',
        subtitle: 'CTO',
        linearGradientColors: ['#F44336', '#E91E63'],
    },
];

const realTestDataList = [
    {
        "id": "1c0dc78c-0c70-4d5c-b78e-f259e69ddca7",
        "created": "2022-11-09T23:44:20.796Z",
        "amount": 100,
        "condition": "STARTED",
        "createdYear": "2022",
        "endSessionDate": "2022-11-09T23:44:20.793Z",
        "filter": "nofilter",
        "game": "clashroyale",
        "hostId": "kev2316@gmail.com",
        "modified": "2022-11-09T23:44:20.796Z",
        "openSessionDate": "2022-11-09T23:44:20.793Z",
        "password": null,
        "privacy": "public",
        "state": "N/A",
        "bestOf": "3",
        "bestOfLastFinishedDate": "2023-02-14T00:22:54.000Z",
        "bestOfPlayProgress": 3,
        "bestOfResults": [
            {
                "bestOfPlayProgress": 1,
                "gameResult": {
                    "matchPlayedDate": "2023-02-14T00:11:24.000Z",
                    "team1": false,
                    "team2": true
                }
            },
            {
                "bestOfPlayProgress": 2,
                "gameResult": {
                    "matchPlayedDate": "2023-02-14T00:22:54.000Z",
                    "team1": true,
                    "team2": false
                }
            },
            {
                "bestOfPlayProgress": 3,
                "gameResult": {
                    "matchPlayedDate": "2023-02-14T00:25:54.000Z",
                    "team1": true,
                    "team2": false
                }
            }
        ],
        "teams": [
            {
                "id": "bypass",
                "gameId": "zeroCool",
                "isHost": true,
                "teamId": 1
            }
        ],
        "gameResult": {
            "team1": false,
            "team2": true
        },
        "type": "pvp",
        "username": 'bypass'
    },
    {
        "id": "8c0dc78c-0c70-4d5c-b78e-f259a69ddda7",
        "created": "2022-12-09T23:44:20.796Z",
        "amount": 1000,
        "condition": "STARTED",
        "createdYear": "2022",
        "endSessionDate": "2022-12-09T23:44:20.796Z",
        "filter": "nofilter",
        "game": "clashroyale",
        "hostId": "kev2316@gmail.com",
        "modified": "2022-12-09T23:44:20.796Z",
        "openSessionDate": "2022-12-09T23:44:20.796Z",
        "password": null,
        "privacy": "public",
        "state": "N/A",
        "teams": [
            {
                "id": "bypass",
                "gameId": "crashOverride",
                "isHost": true,
                "teamId": 1
            }
        ],
        "gameResult": {
            "team1": false,
            "team2": true
        },
        "type": "pvp",
        "username": 'bypass'
    },
    {
        "id": "7c0d348c-5c61-4d5f-b78e-f259a69ddda7",
        "created": "2022-12-10T23:44:20.796Z",
        "amount": 1300,
        "condition": "STARTED",
        "createdYear": "2022",
        "endSessionDate": "2022-12-10T23:44:20.796Z",
        "filter": "nofilter",
        "game": "clashroyale",
        "hostId": "kev2316@gmail.com",
        "modified": "2022-12-10T23:44:20.796Z",
        "openSessionDate": "2022-12-10T23:44:20.796Z",
        "password": null,
        "privacy": "public",
        "state": "N/A",
        "teams": [
            {
                "id": "bypass",
                "gameId": "darkDante",
                "isHost": true,
                "teamId": 1
            }
        ],
        "gameResult": {
            "team1": false,
            "team2": true
        },
        "type": "pvp",
        "username": 'bypass'
    }
]

const longListTestData = [
    ...listTestData,
    ...listTestData,
    ...listTestData,
    ...listTestData
];

export {
    listTestData,
    realTestDataList,
    longListTestData
}