import { AuthStorage } from '../../../../services/storage/auth-storage';
import { AppStorage } from '../../../../services/storage/app-storage';
import { appApi } from '../../../../services/api';

const betSubmissionWalletCheck = async (Toast, betAmount) => {
    const wallet = await AppStorage.getCurrentWalletByType();
    if (!wallet || !wallet.pk) {
        Toast.show({ type: 'error', text1: 'IMPORT WALLET!', text2: 'NO WALLET FOUND!' })
        return false;
    }
    // TODO: check balance here as well, must have balance greater than amount placed.
    try {
        const balance = (await appApi.getWalletBalance(wallet.type, wallet.pk)).balance;
        if (balance === 'N/A') {
            Toast.show({ type: 'error', text1: 'INSUFFICENT FUNDS!', text2: 'WALLET BALANCE BELOW BET AMOUNT!' });
            return false;
        }
        if (parseInt(balance) < parseInt(betAmount)) {
            Toast.show({ type: 'error', text1: 'INSUFFICENT FUNDS!', text2: 'WALLET BALANCE BELOW BET AMOUNT!' })
            return false;
        }
    } catch (e) {
        Toast.show({ type: 'error', text1: 'INSUFFICENT FUNDS!', text2: 'WALLET BALANCE BELOW BET AMOUNT!' })
        return false;
    }
    return wallet;
}

const fetchCurrentWallet = async (setValueFunction) => {
    const walletAddress = await AppStorage.getCurrentWalletByType('stellar');
    setValueFunction(walletAddress?.pk || '')
}

const fetchCurrentGameSelectionTag = async (setValueFunction, gameSelection) => {
    const userProfile = await AuthStorage.getUserProfile();
    let gamerTag = '';
    if (userProfile == null || userProfile.gameProfiles == null) {
        return gamerTag;
    }

    if (gameSelection === 'Clash Royale') {
        gamerTag = userProfile?.gameProfiles['clashRoyale'];
    }
    if (gameSelection === 'Halo Infinite') {
        gamerTag = userProfile?.gameProfiles['haloInfinite'];
    }
    setValueFunction(gamerTag)
}

export {
    betSubmissionWalletCheck,
    fetchCurrentWallet,
    fetchCurrentGameSelectionTag
}