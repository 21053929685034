import { StyleSheet } from 'react-native';
import { OFFSET_WHITE_COLOR } from '../../../global/styles';
import { PRIMARY_BLACK_COLOR } from '../../../global/styles';

const listStyles = StyleSheet.create({
    defaultListStyleProps: {
        backgroundColor: OFFSET_WHITE_COLOR
    }
});

const viewStyles = StyleSheet.create({
    labelViewStyle: {
        color: "black",
        fontWeight: 'bold',
        fontSize: 16
    }
});

const createBetStyles = StyleSheet.create({
    textPrimary: {
        marginVertical: 20,
        textAlign: 'center',
        fontSize: 20,
    },
    textSecondary: {
        marginBottom: 10,
        textAlign: 'center',
        fontSize: 17,
    },
    heading: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
    createBetSubmitButtonViewStyle: {
        borderWidth: 1,
        borderTopColor: PRIMARY_BLACK_COLOR,
        borderRightColor: 'white',
        borderLeftColor: 'white',
        borderBottomColor: 'white',
        marginTop: 10,
        marginBottom: 10
    },
    createBetSubmitButtonContainerStyle: {
        width: 200,
        marginTop: 10,
        alignSelf: 'center',
    }
});

const sharedRoundBoarderStyle = {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'transparent'
}

const sharedRoundBoarderBlackStyle = {
    ...sharedRoundBoarderStyle,
    borderWidth: 2,
    borderColor: PRIMARY_BLACK_COLOR
}

const sharedButtonStyles = {
    containerStyle: {
        width: '100%',
        alignContent: 'center',
        alignSelf: 'center',
        marginTop: 10,
    },
    buttonStyle: {
        borderColor: PRIMARY_BLACK_COLOR,
        backgroundColor: PRIMARY_BLACK_COLOR,
        borderRadius: 10,
    },
    titleStyle: { fontSize: 16 }
}

const cardStyles = {
    activeHistoryBetCardContainerSyle: {
        ...sharedRoundBoarderStyle,
        padding: 7
    },
    activeHistoryBetCardWrapperSyle: {}
}

const listHeaderButtonStyles = {
    containerStyle: {
        width: '50%',
        alignContent: 'center',
        alignSelf: 'center',
        paddingRight: 15,
        paddingLeft: 15,
        marginTop: 5,
        marginBottom: 5
    },
    buttonStyle: {
        ...sharedRoundBoarderStyle,
        borderColor: 'teal',
        backgroundColor: 'teal'
    },
}

const activeBetButtonStyles = {
    containerStyle: {
        // height: 25,
        width: '50%',
        // width: '33%', (for a 3 card style)
        alignContent: 'center',
        alignSelf: 'center',
    },
    buttonStyle: {
        width: '100%',
        backgroundColor: 'teal',
        borderColor: 'teal'
    },
}

const filterButtonStyles = {
    containerStyle: {
        ...activeBetButtonStyles.containerStyle,
        paddingRight: 15,
        paddingLeft: 15,
        marginTop: 10
    },
    buttonStyle: {
        ...listHeaderButtonStyles.buttonStyle
    },
}

const sharedBackgroundColorButtonStyles = {
    backgroundColor: PRIMARY_BLACK_COLOR, borderColor: PRIMARY_BLACK_COLOR
}

const bottomViewButtonStyle = {
    backgroundColor: PRIMARY_BLACK_COLOR,
    borderColor: PRIMARY_BLACK_COLOR
}

export {
    cardStyles,
    listStyles,
    createBetStyles,
    sharedRoundBoarderStyle,
    sharedRoundBoarderBlackStyle,
    sharedButtonStyles,
    sharedBackgroundColorButtonStyles,
    activeBetButtonStyles,
    viewStyles,
    bottomViewButtonStyle,
    listHeaderButtonStyles,
    filterButtonStyles
}