import React from 'react';
import { StyleSheet } from 'react-native';
import { Tab } from '@rneui/themed';

export default (props) => {
    const tabItems = () => {
        const tabs = [];
        for (const item of props.tabItems) {
            tabs.push(<Tab.Item titleStyle={styles.tabItem}>{item}</Tab.Item>)
        }
        return tabs;
    }
    return (
        <Tab indicatorStyle={styles.tabIndicator} value={props.value} onChange={props.onChange} dense>
            {tabItems()}
        </Tab>
    )
}

const styles = StyleSheet.create({
    tabIndicator: {
        backgroundColor: 'teal'
    },
    tabItem: {
        color: 'teal'
    }
});