import React from 'react';
import { Icon, Text } from '@rneui/themed';

export default (props) => {
    const rightStyle = props.rightStyle ? props.rightStyle : {};
    const leftStyle = props.leftStyle ? props.leftStyle : {};
    return (<Text style={props?.containerStyle ? props.containerStyle : {}}>
        <Text style={{ ...rightStyle, fontSize: props.fontSize || 14, fontWeight: "bold" }}>{props.leftText}</Text>
        {/* TODO: remove <Text style={{ ...leftStyle, fontSize: props.fontSize || 14 }}> {props.rightText}</Text> */}
        <Text>
            <Text> </Text>
            <Text style={{ ...leftStyle, fontSize: props.fontSize || 14 }}>{props.rightText}</Text>
            {props.icon && <Icon style={{marginLeft: 1}} size={props.fontSize || 15} type="font-awesome" name={props.icon || 'gg'} />}
        </Text>
    </Text>)

}