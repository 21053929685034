import React from 'react';
import { Card, Text } from '@rneui/themed';

export default (props) => {
    const overrideTitleStyle = props.overrideTitleStyle ? props.overrideTitleStyle : {};
    const overrideDividrStyle = props.overrideDividrStyle ? props.overrideDividrStyle : {};
    return (<>
        <Text style={{ fontWeight: 'bold', fontSize: 14, margin: 0, textAlign: 'left', ...overrideTitleStyle }}>{props.title}</Text>
        <Card.Divider style={{ padding: 0, marginTop: 4, marginBottom: 4, ...overrideDividrStyle }} />
    </>);
}