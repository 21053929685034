import React from 'react';
import { StyleSheet } from 'react-native';
import { FAB } from '@rneui/themed';

export default (props) => {
    return (<FAB
        containerStyle={{
            alignItems: 'center',
            alignContent: 'center',
            alignSelf: 'center',
            width: 60,
            height: 60,
            borderRadius: 30,
            backgroundColor: 'teal'
        }}
        style={embeddedStyles.floatingButtonStyle}
        visible={props.visible || true}
        icon={{
            type: 'foundation',
            name: 'list-bullet',
            size: 30,
            color: 'white',
            style: {
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }
        }}
        color="teal"
        // size='large'
        onPress={props.onPress}
    />);
};

const embeddedStyles = StyleSheet.create({
    floatingButtonStyle: {
        alignItems: 'center',
        position: 'absolute',
        width: 60,
        height: 60,
        // TODO: 0 0 and margin?
        right: 15,
        bottom: 30,
        // borderWidth: 30,
        // borderColor: 'xxx',
        // // borderRadius:100
    }
});