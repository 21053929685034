import React from 'react';
import { FlatList } from 'react-native';
import { Text } from '@rneui/themed';
import ListFooter from './ListFooter';
import JoinBet from '../../views/JoinBet';
import BetCard from '../Bet/BetCard';
import { realTestDataList } from '../../data';

export default (props) => {
    const [isRfreshing, setIsRefreshing] = React.useState(false);
    const [isJoinVisible, setIsJoinVisible] = React.useState(false);
    const [selectedBet, setSelectedBet] = React.useState(null);

    const renderRow = ({ item }) => {
        return (
            <BetCard
                title={<>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>Challenge:</Text>
                    <Text style={{ fontWeight: 'bold' }}> {item?.teams?.filter(x => x.isHost)[0].gameId}</Text>
                </>}
                game={item.game}
                type={`${item.type}`.toUpperCase()}
                amount={item.amount}
                // desc="Test Description"
                privacy={item.privacy}
                created={item.created}
                onJoin={() => { setSelectedBet(item); setIsJoinVisible(true) }}
            />
        );
    };

    return (
        <>
            <FlatList
                ListEmptyComponent={<Text style={{ textAlign: 'center' }}>No Bets, Please Create One.</Text>}
                ListFooterComponent={<ListFooter />}
                data={props.data || realTestDataList}
                keyExtractor={(a, index) => index.toString()}
                renderItem={renderRow}
                horizontal={false}
                refreshing={isRfreshing}
                showsVerticalScrollIndicator={false}
                onRefresh={async () => { setIsRefreshing(true); await props.onRefresh(); setIsRefreshing(false) }}
            />
            <JoinBet bet={selectedBet} visible={isJoinVisible} onExit={() => { setIsJoinVisible(!isJoinVisible) }} />
        </>
    )
}