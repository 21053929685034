import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Header as HeaderRNE, Icon } from '@rneui/themed';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { PRIMARY_BLACK_COLOR } from '../../../global/styles';

export default (props) => {
    return (
        <View style={styles.headerContainer} onLayout={props.onLayout || null}>
            <HeaderRNE
                backgroundColor={PRIMARY_BLACK_COLOR}
                leftComponent={<TouchableOpacity onPress={() => { props.onExit() }}>
                    <Icon type="font-awesome" name="close" color="white" />
                </TouchableOpacity>}
                centerComponent={{ text: props.headerText, style: styles.heading }}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    headerContainer: {
        // backgroundColor: '#397af8',
        // marginBottom: 50,
        width: '100%',
    },
    heading: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    }
});