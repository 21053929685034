import BetCard from './BetCard';
import BetCardHeader from './BetCardHeader';
import BetRowDetail from './BetRowDetail';
import BetCardDetails from './BetCardDetails';
import BetCardButton from './BetCardButton';
import CreateBet from '../../views/CreateBet';
import BetOverlayHeader from './BetOverlayHeader';
import HistoryBetCard from './HistoryBetCard';
export default {
    BetCard,
    BetCardHeader,
    BetRowDetail,
    BetCardDetails,
    BetCardButton,
    CreateBet,
    BetOverlayHeader,
    HistoryBetCard
}