import React from 'react';
import { SelectList } from 'react-native-dropdown-select-list';
import { LabelText } from '../components';
import selectGameOptionData from '../data/selectGameOptionData';
const { SELECT_GAME_DATA } = selectGameOptionData;

// TODO: remove (now stored in select game option data)
// const SELECT_GAME_DATA = [
//     { key: '1', value: 'Clash Royale' },
//     { key: '2', value: 'Halo Infinite' },
// ];
export default (props) => {
    return (
        <>
            <LabelText text={props.labelText || 'Select Game:'} />
            <SelectList
                placeholder={'--Game Selection--'}
                setSelected={(val) => props.setSelected(val)}
                data={SELECT_GAME_DATA}
                save="value"
                defaultOption={props.defaultOption}
            />
        </>
    )
}

