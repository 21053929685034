import React from 'react';
import { View } from 'react-native';
import { Button, Icon } from '@rneui/themed';
import ModalViewContainer from './ModalViewContainer';
import { createBetStyles, sharedBackgroundColorButtonStyles, sharedRoundBoarderStyle } from '../../styles';

export default (props) => {
    return (
        <>
            <ModalViewContainer
                overlayStyleOverrides={props.overlayStyleOverrides || undefined}
                isVisible={props.isVisible}
                onPressIn={props.onPressIn}
                onBackdropPress={props.onBackdropPress}
                headerText={props.headerText}
                onExit={props.onExit}
                children={props.children}
                bottomControlComponents={
                    <View style={createBetStyles.createBetSubmitButtonViewStyle}>
                        <Button
                            icon={
                                <Icon
                                    name={props.bottomButtonOptions.iconName}
                                    type="font-awesome"
                                    color="white"
                                    size={15}
                                    iconStyle={{ marginLeft: 10 }}
                                />
                            }
                            type="outline"
                            iconRight={true}
                            title={props.bottomButtonOptions.title}
                            titleStyle={{ color: 'white' }}
                            onPress={props.bottomButtonOptions.onPress}
                            containerStyle={{ ...createBetStyles.createBetSubmitButtonContainerStyle, ...sharedRoundBoarderStyle }}
                            buttonStyle={sharedBackgroundColorButtonStyles}
                            loading={props.bottomButtonOptions.loading || false}
                            loadingProps={{ color: 'teal' }}
                        />
                    </View>}
            />
        </>
    )
}