import axios from 'axios';
import { AuthStorage } from '../storage/auth-storage';
import config from '../../config/config.json';
const API_ENDPOINT = config.API_ENDPOINT;

const buildAuthHeader = async () => {
    return {
        headers: {
            Authorization: await AuthStorage.getAuthToken()
        }
    }
}

/**
 * @param {*} email 
 * @param {*} password 
 */
const signUp = async (email, password) => {
    let response = await axios.post(`${API_ENDPOINT}/signUp`, {
        email,
        password
    });
    if (response.status !== 200) {
        return null;
    }
    return true;
}

/**
 * @param {*} username 
 * @param {*} accessCode 
 */
const confirmSignUp = async (username, accessCode) => {
    let response = await axios.post(`${API_ENDPOINT}/confirmSignUp`, {
        username,
        accessCode
    });
    if (response.status !== 200) {
        return null;
    }
    return true;
}

/**
 * @param {*} authComponent (TODO: we support email but phone number support in future)
 */
const sendAccessCode = async (authComponent) => {
    try {
        let response = await axios.post(`${API_ENDPOINT}/sendAccessCode`, {
            email: authComponent
        });
        if (response.status !== 200) {
            return false;
        }
        return true;
    } catch (e) { }
    return false;
}

const verifyLoginAccessCode = async (email, accessCode) => {
    try {
        let response = await axios.post(`${API_ENDPOINT}/verifyLoginAccessCode`, {
            email,
            accessCode
        });
        if (response.status !== 200) {
            return null;
        }
        return response.data;
    } catch (e) { }
    return false;
}

/**
 * @param {*} username 
 * @param {*} password 
 */
const login = async (username, password) => {
    let response = await axios.post(`${API_ENDPOINT}/login`, {
        username,
        password
    });
    if (response.status !== 200) {
        return false;
    }
    const token = response.data ? response.data.authToken : null;
    await AuthStorage.setAuthToken(token);
    return true;
}

/**
 * @param {*} username 
 * @param {*} newPassword 
 * @param {*} accessCode --> number
 */
const forgotPassword = async (username, newPassword, accessCode) => {
    let response = await axios.post(`${API_ENDPOINT}/forgotPassword`, {
        username,
        newPassword,
        accessCode
    });
    if (response.status !== 200) {
        return null;
    }
    return true;
}

/**
 * If Token valid 200 response and we are logged in
 */
const isAccessTokenValid = async () => {
    try {
        let response = await axios.get(`${API_ENDPOINT}/isAccessTokenValid`, {
            ...(await buildAuthHeader())
        });
        if (response.status !== 200) {
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
}

export default {
    signUp,
    confirmSignUp,
    sendAccessCode,
    verifyLoginAccessCode,
    login,
    forgotPassword,
    isAccessTokenValid
}