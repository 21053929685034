const validateBet = (bet, ignoreList = ['password', 'filter']) => {
    for (let key of Object.keys(bet)) {
        if (ignoreList.includes(key)) {
            continue;
        }
        if (!bet[key]) {
            console.log("FAILED KEY:", key)
            return false;
        }
    }
    return true;
}

export default {
    validateBet
}