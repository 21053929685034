import React from 'react';
import { ScrollView } from 'react-native';
import { Bet } from '../../components';

export default (props) => {
    return (
        <>
            <Bet.BetOverlayHeader
                headerText={props.headerText}
                onExit={props.onExit}
            />

            <ScrollView
                style={{
                    paddingTop: 0,
                    paddingRight: 10,
                    paddingBottom: 10,
                    paddingLeft: 10
                }}
                showsVerticalScrollIndicator={false}>
                {props.children}
            </ScrollView>
        </>
    );
};