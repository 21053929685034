import React from 'react';
import { View } from 'react-native';
import { Text, ListItem } from '@rneui/themed';
export default (props) => {
    return (
        <ListItem style={{ marginTop: 10 }}>
            <ListItem.Content>
                <View style={{ padding: 20, marginBottom: 20}}>
                    <Text style={{ textAlign: 'center' }}>If you think or anyone you know has a gambling problem and wants help, call 1-800-522-4700.</Text>
                </View>
            </ListItem.Content>
        </ListItem>
    );
}