import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Button, Dialog, Input, Text, Icon } from '@rneui/themed';
import Toast from 'react-native-toast-message';
import { VerifyModal } from './components';
import { appApi, authApi } from '../../services/api';
import { AuthStorage } from '../../services/storage/auth-storage';
import { PRIMARY_BLACK_COLOR } from '../../global/styles';
import { sharedRoundBoarderStyle } from '../main/styles';

export default ({ navigation }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState();
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [verifyCode, setVerifyCode] = useState();
    const [modalActive, setModalActive] = useState(false);

    // TODO: enhance flow here
    useEffect(() => {
        (async () => {
            setIsLoading(true);

            // TODO: remove this, its for testing
            const loggedInEmailBypass = await AuthStorage.getUserEmail();
            if (loggedInEmailBypass === 'bypass') {
                setIsLoading(false);
                navigation && navigation.replace('MainNavigator');
            }

            const authToken = await AuthStorage.getAuthToken();
            if (!!authToken && await authApi.isAccessTokenValid()) {
                // TODO: maybe move this?, refresh profile here
                const userProfile = await appApi.getUserProfile();
                await AuthStorage.setUserProfile(userProfile);
                setIsLoading(false);
                navigation && navigation.replace('MainNavigator');
            }
            setIsLoading(false);
        })();
    }, []);

    const onSendCodeButtonPress = async () => {
        // TODO: remove testing code below
        if (email === 'bypass') {
            // TODO: remove
            await AuthStorage.setUserEmail('bypass');
            setModalActive(false);
            navigation && navigation.replace('MainNavigator');
            return;
        }

        if (!email) {
            Toast.show({ type: 'warn', text1: 'Must provide valid email.' });
            return;
        }
        if (await authApi.sendAccessCode(email)) {
            // pop up modal
            setModalActive(true);
            return;
        }
        // toast error
        Toast.show({ type: 'error', text1: 'Failure, Try Again.' });
    };

    const onVerifyCodeButtonPress = async () => {
        // TODO: either here or in the verify get token and set it
        const verifyResult = await authApi.verifyLoginAccessCode(email, verifyCode);
        if (verifyResult) {
            await AuthStorage.setAuthToken(verifyResult.authToken);
            await AuthStorage.setUserEmail(email);
            // pop up modal
            setModalActive(false);
            navigation && navigation.replace('MainNavigator');
            return;
        }
        // toast error
        Toast.show({ type: 'error', text1: 'Failure To Authenticate.' });
    };

    return (
        <View style={styles.container}>
            {!!isLoading && <ActivityIndicator color="teal" style={{
                height: '100%',
                width: '100%',
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center'
            }} animating={isLoading} size="large" />}

            <VerifyModal
                isVisible={modalActive}
                onBackdropPress={() => setModalActive(false)}
                value={verifyCode}
                onChangeText={setVerifyCode}
                onPress={onVerifyCodeButtonPress}
            />

            <View style={styles.headerContainer}>
                <Text h3 h3Style={{ color: 'white' }}>
                    Welcome To Ewager
                </Text>
                <Text
                    style={styles.signInLabel}>
                    Sign In/Create your account
                </Text>
            </View>

            <View style={styles.formContainer}>
                <Input
                    style={{ color: 'white' }}
                    placeholder='Email'
                    rightIcon={<Icon
                        name='user'
                        type='font-awesome'
                        color='teal'
                    />}
                    value={email}
                    onChangeText={setEmail}
                />
            </View>

            <Button
                title="Send Code"
                loading={false}
                loadingProps={{ size: 'small', color: 'white' }}
                buttonStyle={{
                    ...sharedRoundBoarderStyle,
                    backgroundColor: 'white',
                }}
                titleStyle={{ fontWeight: 'bold', fontSize: 23, color: "black" }}
                containerStyle={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    width: '100%'
                }}
                onPress={onSendCodeButtonPress}
            />

            <Button
                style={styles.signUpButton}
                type="clear"
                titleStyle={{ color: 'white', fontSize: '15px' }}
                // onPress={onIssuesCLick}>
                onPress={() => { setIsDialogVisible(!isDialogVisible) }}>
                Having Issues? Click Here
            </Button>

            <Dialog
                isVisible={isDialogVisible}
                onBackdropPress={() => { setIsDialogVisible(!isDialogVisible) }}
                style={{ padding: 0 }}
                overlayStyle={{ padding: 10 }}
            >
                <Dialog.Title titleStyle={{ textAlign: 'center', textDecorationLine: 'underline' }} title="Support" />
                <Text style={{ textAlign: 'center' }}>Please email support at support@ewager.online</Text>
            </Dialog>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: PRIMARY_BLACK_COLOR
    },
    headerContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 216,
    },
    formContainer: {
        flex: 1,
        marginTop: 32,
        paddingHorizontal: 16,
    },
    signInLabel: {
        marginTop: 16,
        color: 'white'
    },
    signInButton: {
        marginHorizontal: 16,
    },
    verifyModalButton: {
        width: "100%",
        borderColor: "black",
    },
    signUpButton: {
        marginVertical: 12,
        marginHorizontal: 16,
        marginBottom: 30,
        color: 'white'
    },
    forgotPasswordContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    passwordInput: {
        marginTop: 16,
    },
    forgotPasswordButton: {
        paddingHorizontal: 0,
    }
});