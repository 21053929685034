import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { Text } from '@rneui/themed';
import ActiveBetCard from '../Bet/ActiveBetCard';
import { Chat } from '../../views'
import { appApi } from '../../../../services/api';
import { realTestDataList } from '../../data';
import { AuthStorage } from '../../../../services/storage/auth-storage';
import { listStyles } from '../../styles';

export default (props) => {
    const [profileEmailId, setProfileEmailId] = React.useState('');
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [chatSessionData, setChatSessionData] = useState();

    const fetchAuth = async () => {
        const email = await AuthStorage.getUserEmail();
        setProfileEmailId(`${email}`);
    }

    useEffect(() => {
        fetchAuth();
    }, []);

    const handleDeletePress = async (item) => {
        await appApi.deleteBet(item.sessionId);
        // TODO: make this wait better
        await new Promise(res => setTimeout(res, 2500));
        await props.onRefresh();
    }

    const renderRow = ({ item }) => {
        const host = item?.teams?.filter(x => x.isHost)[0];
        const hostGameId = host.gameId;
        return (
            <ActiveBetCard
                title={<>
                    <Text style={{ color: 'black' }}>Challenge:</Text>
                    <Text style={{}}> {hostGameId}</Text>
                </>}
                // TODO: also can't delete if bet is fully funded / started, so keep that in mind, 
                // api for now will handle back notice on api call (in the future we could also support leave)
                deleteDisabled={!(profileEmailId === host.id)}
                game={item.game}
                type={`${item.type}`.toUpperCase()}
                amount={item.amount}
                // desc="Test Description"
                privacy={item.privacy}
                created={item.created}
                onDelete={async () => { await handleDeletePress(item) }}
                onShare={() => { alert("COMING SOON") }}
                onChat={() => {
                    setChatSessionData(item);
                    setIsChatVisible(!isChatVisible);
                }}
            />
        );
    };

    return (
        <>
            <FlatList
                style={listStyles.defaultListStyleProps}
                ListEmptyComponent={<Text style={{ textAlign: 'center' }}>No Active Bets, Please Create One.</Text>}
                data={props.data || realTestDataList}
                keyExtractor={(a, index) => index.toString()}
                renderItem={renderRow}
                horizontal={false}
                showsVerticalScrollIndicator={false}
            />
            <Chat
                // TODO: add and track currently clicked chat, when they close it close connection as well (handled in chat container)
                isVisible={isChatVisible}
                onExit={() => { setIsChatVisible(!isChatVisible) }}
                chatSessionData={chatSessionData}
            />
        </>
    )
}